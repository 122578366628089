import {Environment} from './environment.interface';

export const environment: Environment = {
  beta: true,
  baseDomain: 'beta.intelligence.enverus.dev', // This seems very redundant but getting a reliable base url from angular universal is an especially complicated process.
  API: {
    vaultProtected: 'https://beta.intelligence.enverus.dev/cgw',
    vaultPublic: 'https://beta.intelligence.enverus.dev/cgp',
    azureSearch: 'https://rs-vault-dev.search.windows.net',
    prismData: 'https://data-access-service.ci-develop.crunch.prism.enverus.dev/sql',
    chatBot: {
      websocket: 'wss://service.int.dev.genai.enverus.com/ws',
      feedback: 'https://service.int.dev.genai.enverus.com/feedback/',
      api: 'https://service.int.dev.genai.enverus.com'
    }
  },
  SSR: {
    prependPath: 'https://preview.intelligence.enverus.com'
  },
  SEARCH: {
    index: 'rs-vault-dev',
    scoringProfile: 'beta',
    modelIndex: 'intelligence-vault-downloads',
    queryKey: 'AD53CD22C7BC78F085F350AB97E843E1',
    service: 'rs-vault-dev',
    version: '2023-07-01-Preview'
  },
  AUTH_CONFIG: {
    clientID: 'D6AR0kjT38UvmZAAFu4Hq9Dqj2dsCeL9',
    domain: 'login.auth.enverus.dev',
    audience: 'https://api.enverus.com',
    callbackUri: 'https://beta.intelligence.enverus.dev/callback',
    cacheLocation: 'localstorage'
  },
  CDN: 'https://assets.intelligence.enverus.dev/',
  SEGMENTS: {
    publishUserIds: [
      'samlp|Enverus-IDP|Daniel.Coy@drillinginfo.com',
      // 'samlp|Enverus-IDP|Lorne.Chartier@drillinginfo.com',
    ]
  },
  ALERTS: {
    url: 'https://user-service.ci-develop.crunch.prism.enverus.dev/'
  },
  CHATBOT: {
    disclosureAes256Key: '7143473506201D66410AFAFB8313825BCE87B60B5A54C26AF2B59D50B5937FBE'
  },
  RUDDERSTACK: {
    CDN: 'https://ext-rs-cdn.dev.cts.enverus.com',
    API: 'https://ext-rs-api.dev.cts.enverus.com',
    URL: 'https://ext-rs-dp.dev.cts.enverus.com',
    KEY: '2dXMC6Al3iAjJSkWETJ63uqDA9Q'
  },
  PDFTRON: {
    key: 'RW52ZXJ1cyBJbnRlbGxpZ2VuY2UgUmVzZWFyY2ggIEluYyAgIGZrYSBSUyBFbmVyZ3kgR3JvdXAgQ2FuYWRhICBJbmMgIDpPRU06UlMgSW50ZWxsaWdlbmNlIE1vZHVsZTo6Qis6QU1TKDIwMjUwNzA3KTpDN0E1ODBDRDA0RTc4NjBBODM2MEIxM0FDOTgyNTM3ODYwNjEzRkZEODdCMTJBNDNFNTA0NTVCMjU0RjUwRUQ2MjRDQUI2RjVDNw=='
  }
};

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Inject,
  Input,
  OnInit,
  Output, PLATFORM_ID,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule, isPlatformServer} from '@angular/common';

import {Subject} from 'rxjs';

import {environment as ENV} from '../../../environments/environment';
import {UserService} from '../../services/user/user.service';

@Component({
  selector: 'iv-pdftron',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './pdftron.component.html',
  styleUrls: ['./pdftron.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PdftronComponent implements OnInit, AfterViewInit {

  // onChanges = new Subject<SimpleChanges>();
  @Input() document: string = '';
  @Input() options: any = {};
  // @ViewChild('viewer', {static: true}) viewer!: ElementRef;
  @Output() pdfWordCount = new EventEmitter<string>();

  // webViewer: any;
  readonly disabledElements: any = [
    // 'menuButton',
    'downloadButton',
    'printButton',
    'themeChangeButton',
    'saveAsButton',
    'settingsButton',
    // 'fullscreenButton',

    'toolbarGroup-Shapes',
    'toolbarGroup-Edit',
    'toolbarGroup-Insert',
    'toolbarGroup-Annotate',
    'toolbarGroup-FillAndSign',
    'toolbarGroup-Forms',

    'notesPanelToggle',

    'outlinesPanel-tabPanel',
    'bookmarksPanel-tabPanel',
    'fileAttachmentPanel-tabPanel',
  ];

  profile: any;
  preferences: any;

  @ViewChild('viewer') viewer!: ElementRef;

  @Output() coreControlsEvent:EventEmitter<string> = new EventEmitter();

  private documentLoaded$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private userService: UserService
  ) {
    this.profile = this.userService.getUserProfile();
    this.preferences = this.userService.getPreferences();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

    if(!isPlatformServer(this.platformId)) {
      this.initPdftronWebviewer();
    }

  }

  private async initPdftronWebviewer() {

    if(!isPlatformServer(this.platformId)) {
      const WebViewer = (await import('@pdftron/webviewer')).default;
      WebViewer({
        css: 'assets/pdftron-custom-css/main.css',
        path: 'assets/pdftron',
        initialDoc: this.document,
        extension: 'pdf',
        licenseKey: atob(ENV.PDFTRON.key),
        enableMeasurement: true,
        disabledElements: this.disabledElements,
        // disableVirtualDisplayMode: true
      }, this.viewer.nativeElement).then(instance => {

        const LayoutMode = instance.UI.LayoutMode;
        instance.UI.setLayoutMode(LayoutMode.Single);
        instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
        instance.UI.setTheme(instance.UI.Theme.DARK);

        const {documentViewer} = instance.Core;
        documentViewer.addEventListener('documentLoaded', async () => {
          this.documentLoaded$.next();

          // REF: options set on old viewer
          //     if (this.preferences.theme === 'dark') {
          //       instance.setTheme('dark');
          //     }
          //     instance.disableDownload();
          //     instance.disableFeatures(instance.Feature.LocalStorage);
          //     instance.setFitMode(instance.FitMode.FitWidth);
          //     instance.setActiveLeftPanel('thumbnailsPanel');
          //     instance.setToolbarGroup('toolbarGroup-View');
          //     instance.setAnnotationUser(this.profile.firstname);

          const pageCount: number = documentViewer.getPageCount();
          const doc = documentViewer.getDocument();

          // extract document text so can estimate time to read
          let text: string = '';
          for (let i: number = 1; i <= pageCount; i++) {
            text = text + ' ' + await doc.loadPageText(i);
          }
          this.pdfWordCount.emit(text);

          // if url parameter ?pdfPage=# passed in, jump to that page
          if (this.options && this.options.page > 1) {
            documentViewer.setCurrentPage(this.options.page, true);
            const el = document.getElementById('pdf-preview');
            if (el) {
              el.scrollIntoView();
            } else {
              document.getElementsByTagName('iv-research-detail')[0].scrollIntoView();
            }
          }

        });

      });

    }

  }

  // ngOnChanges(changes: SimpleChanges) {
  //   this.onChanges.next(changes);
  // }

  // configurePDFTron(options: any) {
  //   if (options) {
  //     const instance = this.getInstance();
  //     if (options.download) {
  //       instance.enableDownload();
  //     }
  //   }
  // }

  // openDocument(filepath: any) {
  //   if (filepath !== this.document) {
  //     this.getInstance().loadDocument(encodeURIComponent(filepath));
  //   }
  // }

  // getInstance(): any {
  //   return this.webViewer.getInstance();
  // }

  // getWindow(): any {
  //   return this.viewer.nativeElement.querySelector('iframe').contentWindow;
  // }

  // getElement(): any {
  //   return this.viewer.nativeElement;
  // }

}

<div class="container">
  <div (click)="searchFiltersClose()"></div>
  <div class="filter-container">
    <header>
      <h1 *ngIf="pane === 'Reports'">{{pane}} Filters</h1>
      <h1 *ngIf="pane === 'Livefeed'">Live Feed Filters</h1>
      <button (click)="searchFiltersClose()" class="close">
        <mat-icon>close</mat-icon>
      </button>
    </header>
    <aside>
      <!-- controls -->
      <button class="clear-filters" (click)="clearFilter(); updateAllFilteredFacets()">Clear All Filters</button>
      <div *ngIf="taggingErrorMsg">
        <p class="tagging-error-msg">{{taggingErrorMsg}}</p>
      </div>
      <mat-accordion [multi]="true" #savedFilters>
        <mat-expansion-panel #savedFiltersExpPanel>
          <mat-expansion-panel-header class="saved-filters-header">
            <mat-panel-title>
              Saved Filters
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngIf="activeFilter" class="save-filter">
            <p>Create / Update Saved Filter</p>
            <input placeholder="FilterName" aria-label="FilterName" [formControl]="filterNameCtrl" (change)="searchService.checkActiveFilterChanged(store.collection,filterNameCtrl.value)">
            <button *ngIf="activeFilter.id === 0" [disabled]="!activeFilter.filterModified" (click)="saveFilter()">Save</button>
            <button *ngIf="activeFilter.id > 0" [disabled]="!activeFilter.filterModified" (click)="saveFilter()">Update</button>
            <button *ngIf="activeFilter.id > 0" (click)="cloneFilter()" class="create-new">Create New</button>
          </div>
          <ul *ngIf="loadedFilters?.length">
            <ng-container *ngFor="let collection of collections">
              <div><i>--- {{insertSpaceBeforeCapital(collection)}} ---</i></div>
              <ng-container *ngFor="let filter of loadedFilters | search:'title':searchTerm; trackBy: getFilterId">
                <ng-container *ngIf="filter.settings.collection === collection">
                  <li *ngIf="filter && !filter.isGlobal" class="{{filter?.active && (filter.settings.collection === store.collection) ? 'active' : ''}}" (click)="loadFilter($event,filter)">
                    <div class="filter-title">
                      {{filter.title}}
                    </div>
                    <div class="spacer"></div>
                    <div class="actions">
                      <button *ngIf="(profile.user_id === filter.userId) && (filter.settings.collection !== 'Downloads') && ((activeFilter.id !== filter.id) || ((activeFilter.id === filter.id) && !activeFilter.filterModified))" mat-icon-button (click)="loadFilter($event, filter, false, false, false, true)" matTooltip="Email Alerts" matTooltipClass="sf-actions-tooltip">
                        <mat-icon class="{{filter?.alert ? 'has-alert' : ''}}">mail_outline</mat-icon>
                      </button>
                      <button *ngIf="(profile.user_id === filter.userId) && ((activeFilter.id === filter.id) && activeFilter.filterModified)" mat-icon-button (click)="doNothing($event)" matTooltip="Filter is modified but not saved. Save or reload your filter to assign email notifications to it." matTooltipClass="sf-actions-tooltip">
                        <mat-icon class="alerts-disabled">mail_outline</mat-icon>
                      </button>
                      <button *ngIf="(profile.user_id === filter.userId)" mat-icon-button (click)="loadFilter($event, filter, true, false, true)" matTooltip="Share filter" matTooltipClass="sf-actions-tooltip">
                        <mat-icon>share</mat-icon>
                      </button>
                      <button *ngIf="!filter?.isGlobal" mat-icon-button (click)="deleteFilter($event, filter?.id)" class="delete" matTooltip="Delete Filter" matTooltipClass="sf-actions-tooltip">
                        <mat-icon>delete_forever</mat-icon>
                      </button>
                      <button *ngIf="allowFilterPublish && (profile.user_id === filter.userId) && !filter?.isGlobal" (click)="publishFilter($event, filter?.id, true)" mat-icon-button class="unpublished" matTooltip="Publish Filter Globally" matTooltipClass="sf-actions-tooltip">
                        <mat-icon>public_off</mat-icon>
                      </button>
                      <div class="vert-separator"></div>
                      <button *ngIf="!filter?.isGlobal && !filter.settings.isDefault" mat-icon-button (click)="toggleDefault($event, filter?.id)" class="default" matTooltip="Set as Default filter" matTooltipClass="sf-actions-tooltip">
                        <mat-icon fontSet="material-icons-outlined">push_pin</mat-icon>
                      </button>
                      <button *ngIf="!filter?.isGlobal && filter.settings.isDefault" mat-icon-button (click)="toggleDefault($event, filter?.id)" matTooltip="Remove as Default filter" matTooltipClass="sf-actions-tooltip">
                        <mat-icon class="selected">push_pin</mat-icon>
                      </button>
                    </div>
                  </li>
                </ng-container>
              </ng-container>
            </ng-container>
          </ul>
          <ul *ngIf="globalFiltersCount()">
            <div><i>--- Presets ---</i></div>
            <ng-container *ngFor="let filter of loadedFilters | search:'title':searchTerm; trackBy: getFilterId">
              <li *ngIf="filter && filter.isGlobal" class="{{filter?.active && (filter.settings.collection === store.collection) ? 'active' : ''}}" (click)="loadFilter($event,filter)">
                <div class="filter-title">
                  {{filter.title}}
                </div>
                <div class="spacer"></div>
                <div class="actions">
                  <button *ngIf="(profile.user_id === filter.userId)" mat-icon-button (click)="subscribeToFilter($event, filter)" matTooltip="Email Alerts" matTooltipClass="sf-actions-tooltip">
                    <mat-icon>mail_outline</mat-icon>
                  </button>
                  <button *ngIf="(profile.user_id === filter.userId)" mat-icon-button (click)="loadFilter($event, filter, true, false, true)" matTooltip="Share filter" matTooltipClass="sf-actions-tooltip">
                    <mat-icon>share</mat-icon>
                  </button>
                  <button *ngIf="allowFilterPublish && (profile.user_id === filter.userId) && filter?.isGlobal" (click)="publishFilter($event, filter?.id, false)" mat-icon-button class="published" matTooltip="Un-Publish Filter Globally" matTooltipClass="sf-actions-tooltip">
                    <mat-icon class="published-filter">public</mat-icon>
                  </button>
                  <button *ngIf="filter?.isGlobal && (profile.user_id !== filter.userId)" mat-icon-button matTooltip="Global Filter is Read Only" matTooltipClass="sf-actions-tooltip" class="published" readonly>
                    <mat-icon class="published-filter">edit_off</mat-icon>
                  </button>
                </div>
              </li>
            </ng-container>
          </ul>

        </mat-expansion-panel>
      </mat-accordion>
    </aside>
    <section>
      <mat-accordion class="filters">
        <!-- search input box -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Search
              <mat-icon (click)="keywordSearchHelp($event)">help_outline</mat-icon>
            </mat-panel-title>
            <mat-panel-description>
              <span class="filtered-crumb" *ngIf="searchCtrl.value">
              <svg type="filter" height="16px" width="16px" viewBox="0 0 24 24" fill-rule="evenodd" vector-effect="non-scaling-stroke" style="overflow: visible; pointer-events: auto;"><path data-testid="rs-icon-test" d="M 21.746094 0 L 2.253906 0 C 0.253906 0 -0.753906 2.425781 0.660156 3.839844 L 8.25 11.433594 L 8.25 19.5 C 8.25 20.207031 8.582031 20.875 9.148438 21.375 L 12.148438 23.582031 C 13.617188 24.605469 15.75 23.652344 15.75 21.78125 L 15.75 11.433594 L 23.339844 3.839844 C 24.753906 2.429688 23.75 0 21.746094 0 Z M 13.5 10.5 L 13.5 21.75 L 10.5 19.5 L 10.5 10.5 L 2.25 2.25 L 21.75 2.25 Z M 13.5 10.5"></path></svg>
              </span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="filternsearch">
            <input placeholder="Search" aria-label="Search" [formControl]="searchCtrl" [readOnly]="isGlobalReadOnly()" (keyup.enter)="keywordSearch($event)">
            <button (click)="searchService.navigateToPage(store.collection, searchCtrl.value, 1)">
              <mat-icon>arrow_forward</mat-icon>
            </button>
            <button [disabled]="isGlobalReadOnly()" (click)="clearSearchText($event)" class="searchresults">
              <mat-icon>cancel</mat-icon>
            </button>
          </div>
        </mat-expansion-panel>
        <!-- search filters -->
        <ng-container *ngFor="let facet of filteredFacets.facets; trackBy: trackByKey">
          <mat-expansion-panel *ngIf="(facet.unfilteredFacetsCount > 0 || facet.type === 'RangeFacet' || facet?.key === 'acl') && ((store.collection !== 'OperatorProfiles') || facet?.key !== 'acl') && (facet?.key !== 'intervals')" [expanded]="(activePanel === facet?.key)">
            <mat-expansion-panel-header (click)="setActivePanel(facet?.key)">
              <mat-panel-title>
                {{mapFilterTitle(facet?.key)}}
              </mat-panel-title>
              <mat-panel-description *ngIf="store.facetCount[facet?.key] > 0 || (facet?.key === 'acl' && azureSearchService.get_subscription_filters_count(store.collection) > 0)">
              <span class="filtered-crumb">
              <svg type="filter" height="16px" width="16px" viewBox="0 0 24 24" fill-rule="evenodd" vector-effect="non-scaling-stroke" style="overflow: visible; pointer-events: auto;"><path d="M 21.746094 0 L 2.253906 0 C 0.253906 0 -0.753906 2.425781 0.660156 3.839844 L 8.25 11.433594 L 8.25 19.5 C 8.25 20.207031 8.582031 20.875 9.148438 21.375 L 12.148438 23.582031 C 13.617188 24.605469 15.75 23.652344 15.75 21.78125 L 15.75 11.433594 L 23.339844 3.839844 C 24.753906 2.429688 23.75 0 21.746094 0 Z M 13.5 10.5 L 13.5 21.75 L 10.5 19.5 L 10.5 10.5 L 2.25 2.25 L 21.75 2.25 Z M 13.5 10.5"></path></svg>
              <ng-container *ngIf="facet?.key !== 'acl'">({{store.facetCount[facet?.key]}})</ng-container>
              <ng-container *ngIf="facet?.key === 'acl'">({{azureSearchService.get_subscription_filters_count(store.collection)}})</ng-container>
              </span>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <iv-date-filter *ngIf="facet?.key === 'publishedDate'" [facet]="facet" [storeKey]="store.collection" [isGlobal]="isGlobalReadOnly()"></iv-date-filter>
            <ng-container *ngIf="facet?.key === 'acl' && (store.collection !== 'OperatorProfiles')">
              <iv-subscription-filter *ngIf="facet?.key === 'acl' && (store.collection !== 'OperatorProfiles')" [pane]="pane" [isGlobal]="isGlobalReadOnly()"></iv-subscription-filter>
            </ng-container>
            <ng-container *ngIf="(facet?.key !== 'acl') && (facet?.key !== 'publishedDate')">
              <div class="facets-spacer"></div>
              <div class="facetsCombiner">
                <div>
                  <ng-container *ngIf="facet?.key !== 'acl'">
                    <div class="search-filters">
                      <input placeholder="Search {{mapFilterTitle(facet?.key)}}" [(ngModel)]="facetValues[facet?.key]" (input)="updateFilteredFacets(facet?.key)">
                      <button  (click)="clearSearchField(facet?.key); updateFilteredFacets(facet?.key)" class="searchresults">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                  </ng-container>
                  <span>Mode:</span>
                  <mat-radio-group (change)="onCombineOptionChange($event, facet)" class="mode-radio-group">
                    <mat-radio-button value="and" [checked]="facet.facetsCombineUsingAnd" [disabled]="isGlobalReadOnly()" matTooltip="Search results must contain ALL of the of the following selections">All</mat-radio-button>
                    <mat-radio-button value="or" [checked]="!facet.facetsCombineUsingAnd" [disabled]="isGlobalReadOnly()" matTooltip="Search results may contain ANY of the of the following selections">Any</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div *ngIf="!isGlobalReadOnly() && (facet?.key !== 'keywords')" class="facetsMassToggle">
                  <p>Select:</p>
                  <mat-checkbox (change)="setAllFacetCheckboxes($event, facet, true)"> All</mat-checkbox>&nbsp;
                  <mat-checkbox (change)="setAllFacetCheckboxes($event, facet, false)"> None</mat-checkbox>
                </div>
              </div>
            </ng-container>

            <ul *ngIf="(facet?.key !== 'publishedDate') && (facet?.key !== 'acl')">
              <cdk-virtual-scroll-viewport itemSize="30" class="filters-viewport">
                <ng-container *cdkVirtualFor="let facetValue of facet.values; trackBy: trackByFacetValue">
                  <li [ngClass]="{'active': facetValue.selected}" (click)="searchFromFacet(store.collection, facet, facetValue, !facetValue.selected)">
                    {{facetValue.value}} ({{facetValue.count}})
                  </li>
                </ng-container>
              </cdk-virtual-scroll-viewport>
            </ul>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </section>
  </div>
  <div (click)="searchFiltersClose()"></div>
</div>

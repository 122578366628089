/*
  TODO:
*/

import {EventEmitter, Injectable, Output, OnInit, inject} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

import { NgProgressModule, NgProgress, NgProgressRef } from 'ngx-progressbar';

import { crc64 } from 'crc64-ecma';
import {BehaviorSubject, interval, Observable, of, switchMap, Subscription, first, takeUntil, Subject} from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { v4 as uuidv4 } from 'uuid';

import { environment as ENV } from '../../../environments/environment';

import { Constants } from "../../constants/constants";
const chatbotFiltersStores: string[] = Constants.LivefeedStores;
const livefeedStores: string[] = Constants.LivefeedStores;
const reportsStores: string[] = Constants.ReportsStores;
const researchSpotlightStores: string[] = Constants.ResearchSpotlightStores;

import { AzureSearchService } from "../azuresearch/azuresearch.service";
import { DataService } from "../data/data.service";
import { NotificationService } from "../notification/notification.service";
import { SharedService } from "../shared/shared.service";
import { SnackbarArticlesService } from "../snackbararticles/snackbararticles.service";
import { UserService } from "../user/user.service";

import { ActiveFilter } from "../../interface/activefilter";
import { Research, createEmptyResearch } from "../../interface/research";
import { SnackbarArticle } from "../../interface/snackbararticles";
import { AzssError, AzssStore, createEmptyAzssStore } from "../../interface/azss";
import { ResearchStore } from "../../store/research-store/research.store";

import {SnackbarArticleComponent} from "../../features/snackbar-article/snackbar-article.component";
import {MatSnackBar, MatSnackBarRef} from "@angular/material/snack-bar";
import {LogService} from '../log/log.service';

// index value for each collection
enum StateInstance {
  StateAll,
  StateReports,
  StateOperatorProfiles,
  StateDownloads,
  StateBookmarks,
  StateReportsNew,
  StateLiveFeedAll,
  StateNews,
  StateAnalystTakes,
  StateGlobalScouting,
  StateLiveFeedNew,
  StateResearchSpotlight,
  StateChatbotFilters
}

class AzssErrorClass extends Error {
  constructor(error: AzssError) {
    super(error.errmsg);
    this.name = 'AzssError';
  }
}

interface SubscriptionItem {
  id: string;
  name: string;
  azureSearchFilter: string;
  active: boolean;
}

// @Injectable()
@Injectable({providedIn: 'root'})
export class SearchService {

  progressRef: NgProgressRef;                                    // progress spinner while ajax calls loading

  reportsAzssSubject: BehaviorSubject<AzssStore> = new BehaviorSubject<AzssStore>(
    createEmptyAzssStore('','Reports'));
  reportsAzss$: Observable<AzssStore> = this.reportsAzssSubject.asObservable();

  livefeedAzssSubject: BehaviorSubject<AzssStore> = new BehaviorSubject<AzssStore>(
    createEmptyAzssStore('','LiveFeedAll'));
  livefeedAzss$: Observable<AzssStore> = this.livefeedAzssSubject.asObservable();

  researchSpotlightAzssSubject: BehaviorSubject<AzssStore> = new BehaviorSubject<AzssStore>(
    createEmptyAzssStore('','ResearchSpotlight'));
  researchSpotlightAzss$: Observable<AzssStore> = this.researchSpotlightAzssSubject.asObservable();

  chatbotAzssSubject: BehaviorSubject<AzssStore> = new BehaviorSubject<AzssStore>(
    createEmptyAzssStore('', Constants.ChatbotFiltersStores[0]));
  chatbotAzss$: Observable<AzssStore> = this.chatbotAzssSubject.asObservable();

  readonly searchParameters: Object = {apiVersion: ENV.SEARCH.version, count: true, top: 20};

  // storeKeys (must be unique; must be in same order as 'enum StateInstance')
  readonly collectionStoreKey: string[] = [
    'All',
    'Reports', 'OperatorProfiles', 'Downloads', 'Bookmarks', 'ReportsNew',
    'LiveFeedAll', 'News', 'AnalystTakes', 'GlobalScouting', 'LiveFeedNew',
    'ResearchSpotlight', Constants.ChatbotFiltersStores[0]
  ];

  // clientKeys (must be in same order as 'enum StateInstance')
  readonly collectionClientKey: string[] = [
    'research',
    'research', 'research', 'models', 'research', 'research',
    'research', 'research', 'research', 'research', 'research',
    'research', 'research'
  ];

  // filters per collection (order not relevant but must match names in collectionStoreKey)
  // NOTE: if you change any rules here you must also update:  ss_search_results_processor_track_new()  !!!
  readonly collectionFilter: Object = {
    All: "(acl/any())",
    Reports: "(collection/any(t: t eq 'Intelligence'))",
    OperatorProfiles: "(collection/any(t: t eq 'Operator Profiles'))",
    Downloads: "",
    Bookmarks: "(acl/any())",
    ReportsNew: "(acl/any()) and not (collection/any(t: t eq 'Live Feed'))",
    LiveFeedAll: "(collection/any(t: t eq 'Live Feed'))",
    News: "(collection/any(t: t eq 'Live Feed') and not (series eq 'Analyst Take') and not (acl/any(t: t eq 'acl:global-scout')))",
    GlobalScouting: "(collection/any(t: t eq 'Live Feed') and (acl/any(t: t eq 'acl:global-scout')))",
    AnalystTakes: "(collection/any(t: t eq 'Live Feed') and (series eq 'Analyst Take'))",
    LiveFeedNew: "(collection/any(t: t eq 'Live Feed'))",
    ResearchSpotlight: "(displayRegion/any(t: t eq 'spotlight'))",
    ChatbotFilters: "(collection/any(t: t eq 'Intelligence'))",
  };

  // this object is copied into each azuresearch store instance (source of truth is gateway client /intelligenceTypes
  subscriptionFilterTemplate: any;

  updateSubscription: Subscription = Subscription.EMPTY;

  newCounts: BehaviorSubject<any> = new BehaviorSubject<any>({
    Reports: {count: 0, reportsCount: 0, livefeedCount: 0},
    OperatorProfiles: {count: 0, reportsCount: 0, livefeedCount: 0},
    ReportsNew: {count: 0, reportsCount: 0, livefeedCount: 0},
    LiveFeedAll: {count: 0, reportsCount: 0, livefeedCount: 0},
    News: {count: 0, reportsCount: 0, livefeedCount: 0},
    GlobalScouting: {count: 0, reportsCount: 0, livefeedCount: 0},
    AnalystTakes: {count: 0, reportsCount: 0, livefeedCount: 0},
    LiveFeedNew: {count: 0, reportsCount: 0, livefeedCount: 0},
    ResearchSpotlight: {count: 0, reportsCount: 0, livefeedCount: 0},
  });


  // ------------------------------------------------------------------------
  //  debug settings
  // ------------------------------------------------------------------------

  debug_cfn: boolean = false;        // show function call console debug info
  debug_err: boolean = false;        // show errors on console
  debug_ui: boolean = false;         // display debug info in the ui

  instanceId: string = uuidv4();

  public researchStore: ResearchStore;
  research$: Observable<Research[]>;

  private articleIntervalId: any = null;
  private articleSnackBarRef: MatSnackBarRef<SnackbarArticleComponent> | null = null;

  private currentUrl: string | undefined = undefined;
  private destroy$ = new Subject<void>();

  constructor(private userService: UserService,
              private ngProgress: NgProgress,
              private route: ActivatedRoute,
              private router: Router,
              private dataService: DataService,
              private azureSearchService: AzureSearchService,
              private notificationService: NotificationService,
              private _researchStore: ResearchStore,
              private sharedService: SharedService,
              private snackbarArticlesService: SnackbarArticlesService,
              private snackBar: MatSnackBar,
              private logService:LogService,
  ) {

    this.researchStore = _researchStore;
    this.research$ = this.researchStore.selectResearch();

    this.progressRef = this.ngProgress.ref();

  }

  private convertToSubscriptionFilterTemplate(array: SubscriptionItem[]): { filters: { key: string; name: string; active: boolean; filter: string }[] } {
    return {
      filters: array.map(item => ({
        key: item.id,
        name: item.name,
        active: false,
        filter: item.azureSearchFilter
      }))
    };
  }

  /**
   * Initialize the store(s).
   */
  public async init_stores(stores: string[]): Promise<void> {

    // ------------------------------------------------------------------------
    // get subscriptions templates
    // ------------------------------------------------------------------------

    this.subscriptionFilterTemplate = this.convertToSubscriptionFilterTemplate(this.sharedService.getSubscriptionsTemplates());


    // ------------------------------------------------------------------------
    // init azureSearchService clients
    // ------------------------------------------------------------------------

    if (this.debug_cfn) {
      this.azureSearchService.set_debug_cfn(false);
    }  // turn off init noise

    // initialize search clients (unique SearchClients only - can reuse the same ones for other collections)
    let azssError: any;
    if ((azssError = this.azureSearchService.init_client(this.collectionClientKey[StateInstance.StateReports],
      ENV.API.azureSearch, ENV.SEARCH.index, ENV.SEARCH.queryKey, ENV.SEARCH.version))) {
    }
    if ((azssError = this.azureSearchService.init_client(this.collectionClientKey[StateInstance.StateDownloads],
      ENV.API.azureSearch, ENV.SEARCH.modelIndex, ENV.SEARCH.queryKey, ENV.SEARCH.version))) {
    }


    // ------------------------------------------------------------------------
    // init azureSearchService stores
    // ------------------------------------------------------------------------

    for (let i = 0; i < (Object.keys(StateInstance).length / 2); i++) {

      if (stores.includes(this.collectionStoreKey[i])) {

        const clientKey = this.collectionClientKey[i];

        let research: Research = createEmptyResearch(this.collectionStoreKey[i]);
        this._researchStore.addResearch(research);

        if ((azssError = this.azureSearchService.init_store(this.collectionStoreKey[i], clientKey))) {
        }

        if ((azssError = this.azureSearchService.set_input(this.collectionStoreKey[i], ''))) {
        }

        if ((i == StateInstance.StateReportsNew) || (i == StateInstance.StateLiveFeedNew)) {

          const newTime: number = Date.now() - (Constants.newArticlesPublishedSeconds * 1000);
          const newTimeISO: string = new Date(newTime).toISOString();
          let searchParametersNew: any = Object.assign({}, this.searchParameters);
          searchParametersNew.additionalFilters = `(publishedDate ge ${newTimeISO})`;
          searchParametersNew.top = 999;

          if ((azssError = this.azureSearchService.update_search_parameters(this.collectionStoreKey[i], searchParametersNew))) {
          }

        } else {
          if ((azssError = this.azureSearchService.update_search_parameters(this.collectionStoreKey[i], this.searchParameters))) {
          }
        }

        // create facets (facets for ClientModels are different than the rest)
        if (i == StateInstance.StateDownloads) {
          this.ss_add_facets_models(this.collectionStoreKey[i]);
        } else {
          this.ss_add_facets(this.collectionStoreKey[i]);
        }

        // results processor callback to set document permissions based on user acls (access level)
        if ((i == StateInstance.StateReportsNew) || (i == StateInstance.StateLiveFeedNew)) {
          if ((azssError = this.azureSearchService.search_results_processor(this.collectionStoreKey[i], this.ss_search_results_processor_track_new.bind(this)))) {
          }

        } else {
          if ((azssError = this.azureSearchService.search_results_processor(this.collectionStoreKey[i], this.ss_search_results_processor.bind(this)))) {
          }

        }

        if (i == StateInstance.StateDownloads) {
          let searchPrefs = this.userService.getPreferences().search;
          searchPrefs.searchFields = ['title', 'filename', 'parentTitle', 'category', 'keywords'];
          this.userService.setPreferences(searchPrefs);
          this.azureSearchService.update_search_parameters(this.collectionStoreKey[i], {'searchFields': ['title', 'filename', 'parentTitle', 'category', 'keywords']});
          this.azureSearchService.update_search_parameters(this.collectionStoreKey[i], {'scoringProfile': 'downloads'});
        }

        this.ss_set_search_preferences(this.collectionStoreKey[i], this.userService.getPreferences().search);

        if ((azssError = this.azureSearchService.set_global_filter(this.collectionStoreKey[i], 'vault',
          (this.collectionFilter as any)[this.collectionStoreKey[i]]))) {
        }

        // we need to clone subscriptionFilter for each store as it contains the 'active' state flag which is independent per collection
        if ((azssError = this.azureSearchService.set_subscription_filters(this.collectionStoreKey[i],
          JSON.parse(JSON.stringify(this.subscriptionFilterTemplate))))) {
        }

        this.ss_set_subscription(this.collectionStoreKey[i], 'my-subscriptions');

        this.azureSearchService.diff_facets(this.collectionStoreKey[i]);

      }

    }
    if (this.debug_cfn) {
      this.azureSearchService.set_debug_cfn(true);
    }  // re-enable debugging function console log

    // ------------------------------------------------------------------------

    const RsStoreKey: string = researchSpotlightStores[0];
    this.azureSearchService.update_search_parameters(RsStoreKey, {
      orderby: 'publishedDate desc',
      top: 8
    });
    this.ss_set_collection(RsStoreKey, false, false, false);
    this.researchSpotlightAzssSubject.next(this.azureSearchService.store[RsStoreKey]);

    // this must be init'ed before searching!
    this.research$.pipe(
      take(1),
    ).subscribe((research2: Research[]) => {
    });

    // setup subscription to watch for path changes
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = this.router.url;
        }
      });

    // update new articles
    if(Constants.newArticlesIntervalEnable) {

      // update every specified interval (search() called for ResearchSpotlight, ReportsNew, LiveFeedNew)
      setTimeout(() => {
        this.updateNewArticles();
        this.updateSubscription = interval((Constants.newArticlesIntervalSeconds) * 1000).subscribe((val: number) => {
          this.updateNewArticles();
        });
      }, (3000));

      // wait before updating the snackbar the first time
      this.articleIntervalId = setTimeout(() => {

        // do not show a snackbar for the article we are currently viewing
        let currentArticleId: string | undefined = undefined;
        if(this.currentUrl !== undefined) {
          currentArticleId = this.extractArticleId(this.currentUrl);
        }
        let nextArticle = null;
        do {
          nextArticle = this.snackbarArticlesService.getNextArticle();
        } while ((nextArticle !== null) && (nextArticle.id === currentArticleId));

        if (nextArticle) {
          this.articleSnackBarRef = this.snackBar.openFromComponent(SnackbarArticleComponent, {
            data: nextArticle,
            duration: Constants.snackbarRotateSeconds * 1000,
            panelClass: ['article-snackbar']
          });
        }
      }, (10000));

      // update snackbar every specified interval
      this.articleIntervalId = setInterval(() => {

        // do not show a snackbar for the article we are currently viewing
        let currentArticleId: string | undefined = undefined;
        if(this.currentUrl !== undefined) {
          currentArticleId = this.extractArticleId(this.currentUrl);
        }
        let nextArticle = null;
        do {
          nextArticle = this.snackbarArticlesService.getNextArticle();
        } while ((nextArticle !== null) && (nextArticle.id === currentArticleId));

        if (nextArticle) {
          this.articleSnackBarRef = this.snackBar.openFromComponent(SnackbarArticleComponent, {
            data: nextArticle,
            duration: Constants.snackbarRotateSeconds * 1000,
            panelClass: ['article-snackbar']
          });
        }
      }, (Constants.snackbarRotateSeconds * 1000));

    }

    // this.azureSearchService.dump_stores();
    return Promise.resolve();

  }

  private extractArticleId(path: string): string | undefined {
    const match = path.match(/\/research\/(\d+)$/);
    return match ? match[1] : undefined;
  }

  private updateNewArticles(): void {
    const newTime: number = Date.now() - (Constants.newArticlesPublishedSeconds * 1000);
    const newTimeISO: string = new Date(newTime).toISOString();

    let searchParametersUpdated: any = {};
    searchParametersUpdated.additionalFilters = `(publishedDate ge ${newTimeISO})`;

    this.azureSearchService.update_search_parameters('ReportsNew', searchParametersUpdated);
    this.search('ReportsNew');

    this.azureSearchService.update_search_parameters('LiveFeedNew', searchParametersUpdated);
    this.search('LiveFeedNew');

    // spotlight has a fixed record count; do not apply time range limiter
    this.search('ResearchSpotlight');
  }

  /**
   * Initialize facets for non-ClientModel collections.
   * @param {string} storeKey - The storeKey of the store to modify.
   */
  private ss_add_facets(storeKey: string): void {
    const startDate = new Date(Constants.publishedStartDate);
    const endDate = new Date();
    this.azureSearchService.add_checkbox_facet(storeKey, 'acl', 'collection', 200, 'value');
    this.azureSearchService.add_checkbox_facet(storeKey, 'series', 'string', 100, 'value');
    this.azureSearchService.add_checkbox_facet(storeKey, 'category', 'collection', 60, 'value');
    this.azureSearchService.add_checkbox_facet(storeKey, 'subCategory', 'collection', 60, 'value');
    this.azureSearchService.add_checkbox_facet(storeKey, 'regions', 'collection', 200, 'value');
    this.azureSearchService.add_checkbox_facet(storeKey, 'basins', 'collection', 1000, 'value');
    this.azureSearchService.add_checkbox_facet(storeKey, 'plays', 'collection', 200, 'value');
    this.azureSearchService.add_checkbox_facet(storeKey, 'countries', 'collection', 300, 'value');
    this.azureSearchService.add_checkbox_facet(storeKey, 'companies', 'collection', 10000, 'value');
    this.azureSearchService.add_checkbox_facet(storeKey, 'solutionSet', 'collection', 100, 'value');
    this.azureSearchService.add_checkbox_facet(storeKey, 'authors', 'collection', 500, 'value');
    // this.azureSearchService.add_checkbox_facet(storeKey, 'keywords', 'collection', 10000, 'value');
    this.azureSearchService.add_range_facet_date(storeKey, 'publishedDate', 'date', startDate, endDate);
    // this.azureSearchService.add_checkbox_facet(storeKey, 'type', 'string', 30, 'value');
    // this.azureSearchService.add_checkbox_facet(storeKey, 'subType', 'string', 30, 'value');
    // this.azureSearchService.add_checkbox_facet(storeKey, 'stockTickers', 'collection', 5000, 'value');
    // this.azureSearchService.add_checkbox_facet(storeKey, 'intervals', 'collection', 200, 'value');
  }

  /**
   * Initialize facets for the ClientModel collection.
   * @param {string} storeKey - The storeKey of the store to modify.
   */
  private ss_add_facets_models(storeKey: string): void {
    const startDate = new Date(Constants.publishedStartDate);
    const endDate = new Date();
    this.azureSearchService.add_checkbox_facet(storeKey, 'acl', 'collection', 200, 'value');
    this.azureSearchService.add_range_facet_date(storeKey, 'publishedDate', 'date', startDate, endDate);
    this.azureSearchService.add_checkbox_facet(storeKey, 'solutionSet', 'collection', 30, 'value');
    this.azureSearchService.add_checkbox_facet(storeKey, 'series', 'string', 100, 'value');
    this.azureSearchService.add_checkbox_facet(storeKey, 'category', 'collection', 200, 'value');
    this.azureSearchService.add_checkbox_facet(storeKey, 'subCategory', 'collection', 200, 'value');
    this.azureSearchService.add_checkbox_facet(storeKey, 'authors', 'collection', 500, 'value');
  }

  /**
   * Process search results - compare user acls vs doc acls and set bool 'permitted' on each document returned.
   * @param {string} results - The search results array to modify.
   */
  public ss_search_results_processor(storeKey: string, results: any): void {
    if (this.debug_cfn) { console.log(`%c search.service::ss_search_results_processor(storeKey ${storeKey})`, 'background: purple; color: white'); }

    const permissions = this.userService.getAcl();

    if (Array.isArray(results)) {
      results.forEach(function (item) {
        let test = item.acl.filter((item: any) =>
          permissions.indexOf(item) >= 0
        );
        item.permitted = test.length > 0;
      });

      if (this.azureSearchService.store[storeKey] !== null && typeof (this.azureSearchService.store[storeKey]) !== 'undefined') {

        if (reportsStores.indexOf(storeKey) >= 0) {
          this.sharedService.reportsTab$.pipe(
            first()
          ).subscribe((reportsTab: string) => {
            if ((storeKey !== 'ReportsNew') || (reportsTab === 'ReportsNew')) {
              this.reportsAzssSubject.next(this.azureSearchService.store[storeKey]);
            }
          });

        } else {

          if (livefeedStores.indexOf(storeKey) >= 0) {
            this.sharedService.livefeedTab$.pipe(
              first()
            ).subscribe((livefeedTab: string) => {
              if ((storeKey !== 'LiveFeedNew') || (livefeedTab === 'LiveFeedNew')) {
                this.livefeedAzssSubject.next(this.azureSearchService.store[storeKey]);
              }
            });

          } else {

            if (chatbotFiltersStores.indexOf(storeKey) >= 0) {
              this.sharedService.reportsTab$.pipe(
                first()
              ).subscribe((reportsTab: string) => {
                if ((storeKey !== 'ReportsNew') || (reportsTab === 'ReportsNew')) {
                  this.chatbotAzssSubject.next(this.azureSearchService.store[storeKey]);
                }
              });

            }

          }

        }

      }

    } else {
    }
  }

  private ss_search_results_processor_track_new(storeKey: string, results: any): void {
    this.ss_search_results_processor(storeKey, results);

    let workingCounts = this.newCounts.getValue();

    // reset counts for current storeKey (pane New tab)
    for (const property in workingCounts) {
      switch (storeKey) {
        case 'ReportsNew':
          workingCounts[property].reportsCount = 0;
          break;
        case 'LiveFeedNew':
          workingCounts[property].livefeedCount = 0;
          break;
      }
    }

    // tally new counts for current storeKey (pane New tab)
    if (Array.isArray(results)) {
      results.forEach(function (item) {

        // console.table({
        //   collection: `${JSON.stringify(item.collection)} (${Array.isArray(item.collection) ? 'array' : typeof(item.collection)})`,
        //   series: `${item.series} (${Array.isArray(item.series) ? 'array' : typeof(item.series)})`,
        //   acl: `${JSON.stringify(item.acl)} (${Array.isArray(item.acl) ? 'array' : typeof(item.acl)})`,
        // });

        // Reports: "(collection/any(t: t eq 'Intelligence'))",
        if (item.hasOwnProperty('collection') && Array.isArray(item.collection) && (item.collection.indexOf('Intelligence') >= 0)) {
          if (workingCounts.hasOwnProperty('Reports')) {
            switch (storeKey) {
              case 'ReportsNew':
                workingCounts['Reports'].reportsCount += 1;
                break;
              case 'LiveFeedNew':
                workingCounts['Reports'].livefeedCount += 1;
                break;
            }
          }
        }

        // OperatorProfiles: "(collection/any(t: t eq 'Operator Profiles'))",
        if (item.hasOwnProperty('collection') && Array.isArray(item.collection) && (item.collection.indexOf('Operator Profiles') >= 0)) {
          if (workingCounts.hasOwnProperty('OperatorProfiles')) {
            switch (storeKey) {
              case 'ReportsNew':
                workingCounts['OperatorProfiles'].reportsCount += 1;
                break;
              case 'LiveFeedNew':
                workingCounts['OperatorProfiles'].livefeedCount += 1;
                break;
            }
          }
        }

        // LiveFeedAll: "(collection/any(t: t eq 'Live Feed'))",
        if (item.hasOwnProperty('collection') && Array.isArray(item.collection) && (item.collection.indexOf('Live Feed') >= 0)) {
          if (workingCounts.hasOwnProperty('LiveFeedAll')) {
            switch (storeKey) {
              case 'ReportsNew':
                workingCounts['LiveFeedAll'].reportsCount += 1;
                break;
              case 'LiveFeedNew':
                workingCounts['LiveFeedAll'].livefeedCount += 1;
                break;
            }
          }
        }

        // News: "(collection/any(t: t eq 'Live Feed') and not (series eq 'Analyst Take') and not (acl/any(t: t eq 'acl:global-scout')))",
        if (item.hasOwnProperty('collection') && Array.isArray(item.collection) && (item.collection.indexOf('Live Feed') >= 0)) {
          if (item.hasOwnProperty('series') && (typeof (item.series) === 'string') && (item.series.indexOf('Analyst Take') < 0)) {
            if (item.hasOwnProperty('acl') && Array.isArray(item.acl) && (item.acl.indexOf('acl:global-scout') < 0)) {
              if (workingCounts.hasOwnProperty('News')) {
                switch (storeKey) {
                  case 'ReportsNew':
                    workingCounts['News'].reportsCount += 1;
                    break;
                  case 'LiveFeedNew':
                    workingCounts['News'].livefeedCount += 1;
                    break;
                }
              }
            }
          }
        }

        // GlobalScouting: "(collection/any(t: t eq 'Live Feed') and (acl/any(t: t eq 'acl:global-scout')))",
        if (item.hasOwnProperty('collection') && Array.isArray(item.collection) && (item.collection.indexOf('Live Feed') >= 0)) {
          if (item.hasOwnProperty('acl') && Array.isArray(item.acl) && (item.acl.indexOf('acl:global-scout') >= 0)) {
            if (workingCounts.hasOwnProperty('GlobalScouting')) {
              switch (storeKey) {
                case 'ReportsNew':
                  workingCounts['GlobalScouting'].reportsCount += 1;
                  break;
                case 'LiveFeedNew':
                  workingCounts['GlobalScouting'].livefeedCount += 1;
                  break;
              }
            }
          }
        }

        // AnalystTakes: "(collection/any(t: t eq 'Live Feed') and (series eq 'Analyst Take'))",
        if (item.hasOwnProperty('collection') && Array.isArray(item.collection) && (item.collection.indexOf('Live Feed') >= 0)) {
          if (item.hasOwnProperty('series') && (typeof (item.series) === 'string') && (item.series.indexOf('Analyst Take') >= 0)) {
            if (workingCounts.hasOwnProperty('AnalystTakes')) {
              switch (storeKey) {
                case 'ReportsNew':
                  workingCounts['AnalystTakes'].reportsCount += 1;
                  break;
                case 'LiveFeedNew':
                  workingCounts['AnalystTakes'].livefeedCount += 1;
                  break;
              }
            }
          }
        }

      });

      // build and sort array of recent articles for use by snackbar system
      const userSub = this.userService.getIdTokenParam('sub');
      let _articles: SnackbarArticle[] = [];
      results.forEach(function (rec) {
        let pubDate: number = new Date(rec.publishedDate).getTime();
        let cutoffDate: number = Date.now() - (Constants.snackbarArticlesPublishedSeconds * 1000);
        if(pubDate > cutoffDate) {
          let sa: SnackbarArticle = {
            id:                   rec.id,
            collection:           rec.collection,
            key:                  rec.key,
            title:                rec.title,
            series:               rec.series,
            authors:              rec.authors,
            publishedDate:        pubDate
          };
          _articles.push(sa);
        }
      });
      if(_articles.length) {
        this.snackbarArticlesService.addUpdateArticles(_articles);
      }

      // add up totals of all panes
      for (const property in workingCounts) {
        workingCounts[property].count = workingCounts[property].reportsCount + workingCounts[property].livefeedCount;
      }

      workingCounts['ReportsNew'].count =
        workingCounts['Reports'].count +
        workingCounts['OperatorProfiles'].count;

      workingCounts['LiveFeedNew'].count =
        workingCounts['LiveFeedAll'].count;

      this.newCounts.next(workingCounts);
    }

  }

  /**
   * Search for documents.
   * @param {boolean} clearFacets - Clear all facets and reset global filters.
   * @param {boolean} searchFromFilter - If we were loading a filter, restore facets from facetsDiff (pre-populated).
   */
  public async search(storeKey: string, clearFacets: boolean = false, searchFromFilter: boolean = false, loadUserPrefs: boolean = true): Promise<AzssError | null> {
    if (this.debug_cfn) { console.log(`%c search.service::search(storeKey: ${storeKey}, clearFacets: ${clearFacets}, searchFromFilter: ${searchFromFilter})`, 'background: lime; color: black'); }

    return new Promise(async (resolve, reject) => {

      try {

        const activeResearch = await this.research$.pipe(
          take(1),
          map(
            research => research.find(data => data.activeStoreKey === storeKey)
          )
        ).toPromise();

        if (activeResearch) {

          if (loadUserPrefs) {
            this.ss_set_search_preferences(storeKey, this.userService.getPreferences().search);
          }

          if (clearFacets) {

            this.azureSearchService.clear_all_facets(activeResearch.activeStoreKey);

            this.azureSearchService.set_global_filter(activeResearch.activeStoreKey, 'subscription', '');

          }

          this.azureSearchService.set_input(activeResearch.activeStoreKey, activeResearch.q);

          this.azureSearchService.set_page(activeResearch.activeStoreKey, activeResearch.page);

          // WE ALWAYS NEED THESE NOW THAT WE HAVE VARIABLE AND/OR IN EACH FACET GROUP! (with exception of stores that do not expose facets for manipulation)
          // NOTE: this causes an extra search call:  "only query facetsMaster if facetsMaster.facets is empty or globalfilter('subscription') has changed"
          if( (activeResearch.activeStoreKey !== Constants.ResearchSpotlightStores[0]) &&
            (activeResearch.activeStoreKey !== 'ReportsNew') &&
            (activeResearch.activeStoreKey !== 'LiveFeedNew') &&
            this.azureSearchService.get_or_groups(activeResearch.activeStoreKey).length
          ) {
            await this.azureSearchService.update_master_facets_gf_subscription(activeResearch.activeStoreKey);
          }

          this.progressRef = this.ngProgress.ref();
          this.progressRef.start();

          // perform the search
          this.azureSearchService.search(activeResearch.activeStoreKey, !searchFromFilter).then(azssError => {

            if (azssError) {
              this.updateChipFacetText(activeResearch);
              this.ss_search_results_processor_track_new(activeResearch.activeStoreKey, this.azureSearchService.get_store(activeResearch.activeStoreKey).results.results);
              this.progressRef.complete();

              if ((storeKey !== 'Bookmarks') && (storeKey !== 'ReportsNew') && (storeKey !== 'LiveFeedNew') && (storeKey !== 'ResearchSpotlight')) {
                this.notificationService.open(`${azssError.errmsg}`, '', 5000, 'success');
              }

              reject(azssError);

            } else {

              if (this.azureSearchService.get_store(activeResearch.activeStoreKey).results.count === 0) {
                this.notificationService.open('No results were found.  Resetting filter state.', '', 5000, 'success');
              }

              const searchParameters = this.azureSearchService.get_search_parameters(activeResearch.activeStoreKey);

              // if we were loading a filter, update the facets ... IS THIS REDUNDANT?
              // if (searchFromFilter) {
              //
              //   if(activeResearch.activeStoreKey === 'Reports') {
              //     console.log(`search::search() -> facetsDiff_to_facets(${activeResearch.activeStoreKey})`);
              //   }
              //
              //   // this.azureSearchService.facetsDiff_to_facets(activeResearch.activeStoreKey, true);
              // }

              if ((activeResearch.activeStoreKey !== 'ReportsNew') && (activeResearch.activeStoreKey !== 'LiveFeedNew') && (activeResearch.activeStoreKey !== 'ResearchSpotlight')) {
                this.updateChipFacetText(activeResearch);
              }

              // detect if the current settings differ from the activeFilter
              if (this.sharedService.isActiveFilterInited(activeResearch.activeStoreKey)) {

                const dateRangeEdited: boolean = this.sharedService.getActiveFilterParameter(activeResearch.activeStoreKey, 'dateRangeEdited');

                this.sharedService.testActiveFilterModified(activeResearch.activeStoreKey,
                  this.azureSearchService.get_facetsdiff(activeResearch.activeStoreKey, Constants.facetsModifiedIgnoreDates || !dateRangeEdited)
                );

              } else {
                // BUG... otherwise we want to test against the baseline facets!
                // this.azureSearchService.dump_stores(activeResearch.activeStoreKey);
              }

              this.progressRef.complete();

              // scroll to page top
              let searchEl = document.querySelector('#search');
              try {
                if ((searchEl !== null) && (searchEl.getBoundingClientRect().bottom <= 0)) {
                  searchEl.scrollIntoView();
                }
              } catch (e) {
              }

              resolve(null);
            }

          })
            .catch((error) => {

              this.updateChipFacetText(activeResearch);
              this.ss_search_results_processor_track_new(activeResearch.activeStoreKey, this.azureSearchService.get_store(activeResearch.activeStoreKey).results.results);
              this.progressRef.complete();

              if ((storeKey !== 'Bookmarks') && (storeKey !== 'ReportsNew') && (storeKey !== 'LiveFeedNew') && (storeKey !== 'ResearchSpotlight')) {
                this.notificationService.open(`${error.message}`, '', 5000, 'success');
              }

              reject(error);
            });

        }

      } catch (error) {
        reject(error);
      }

    });

  }

  private updateChipFacetText(activeResearch: Research): void {

    let includeDateRange: boolean = false;

    let afId = this.sharedService.getActiveFilterParameter(activeResearch.activeStoreKey, 'id');

    let dateRangeEdited = this.sharedService.getActiveFilterParameter(activeResearch.activeStoreKey, 'dateRangeEdited');
    if ((typeof (dateRangeEdited) === 'boolean') && (dateRangeEdited === true)) {
      includeDateRange = true;
    }

    // chip/facets text based on active filter
    if((typeof(afId) === 'number') && (afId > 0)) {

      let store = this.azureSearchService.get_store(activeResearch.activeStoreKey);
      const facetKeys = store.facets.facets.map((facet: any) => facet.key);

      let chipContents: string = `"${this.sharedService.getActiveFilterParameter(activeResearch.activeStoreKey, 'title')}"`;

      // don't compare publishedDate at this time
      let curFacets: any = JSON.parse( JSON.stringify( this.azureSearchService.get_facetsdiff(activeResearch.activeStoreKey, true) ) );

      let afFacets: any = JSON.parse( JSON.stringify( this.sharedService.getActiveFilterParameter(activeResearch.activeStoreKey, 'facetsDiff') ) );

      facetKeys.forEach(function (key: any) {

        const curFacet = curFacets.facets.find((item: any) => item.key === key);
        const afFacet = afFacets.facets.find((item: any) => item.key === key);

        let outputPlus: string = '';
        let outputMinus: string = '';

        // any keys in current not in filter are plus
        if(curFacet && !afFacet) {
          curFacet.values.forEach(function (value: any) {
            outputPlus += ` +${value.value}`;
          });
        }

        // any keys in filter not in current are minus
        if(afFacet && !curFacet) {
          afFacet.values.forEach(function (value: any) {
            outputMinus += ` -${value.value}`;
          });
        }

        // if both keys are present, then need to compare values
        if(afFacet && curFacet) {

          // any values in current not in filter are plus
          curFacet.values.forEach(function (value: any) {
            if(!afFacet.values.find((item: any) => item.value === value.value)) {
              outputPlus += ` +${value.value}`;
            }
          });

          // any values in filter not in current are minus
          afFacet.values.forEach(function (value: any) {
            if(!curFacet.values.find((item: any) => item.value === value.value)) {
              outputMinus += ` -${value.value}`;
            }
          });

        }

        if(outputPlus.length) {
          chipContents += outputPlus;
        }
        if(outputMinus.length) {
          chipContents += outputMinus;
        }
        if(outputPlus.length || outputMinus.length) {
          chipContents += ';';
        }

      });


      // figure out how the
      this.setChipContents(activeResearch.activeStoreKey, chipContents);
      this.sharedService.updateChipPrettyText(activeResearch.activeStoreKey, chipContents);

      this.sharedService.updateFacetsPrettyText(activeResearch.activeStoreKey,
        this.azureSearchService.get_facetsdiff_pretty_text(activeResearch.activeStoreKey,
          ', ', '\n', true, (activeResearch.q.length ? `Keyword "${activeResearch.q}"` : ''),
          false, false, 0, includeDateRange)
      );

      // chip/facets text based on selected facets, etc...
    } else {

      const chipContents: string = this.azureSearchService.get_facetsdiff_pretty_text(activeResearch.activeStoreKey,
        ', ', '; ', false, (activeResearch.q.length ? `"${activeResearch.q}"` : ''),
        true, false, Constants.facetsChipTextLimit, includeDateRange);
      this.setChipContents(activeResearch.activeStoreKey, chipContents);
      this.sharedService.updateChipPrettyText(activeResearch.activeStoreKey, chipContents);

      this.sharedService.updateFacetsPrettyText(activeResearch.activeStoreKey,
        this.azureSearchService.get_facetsdiff_pretty_text(activeResearch.activeStoreKey,
          ', ', '\n', true, (activeResearch.q.length ? `Keyword "${activeResearch.q}"` : ''),
          false, false, 0, includeDateRange)
      );

    }

  }

  /**
   * Update facet and perform a search.
   * @param {any} facet - The facet object.
   * @param {any} facetValue - The changed facet value object.
   * @desc Note is only called when a facet is selected in search-filters component.
   */
  public ss_search_from_facet(storeKey: string, facet: any, facetValue: any): void {
    if (this.debug_cfn) { console.log(`%c search.service::searchFromFacet(${facet}, ${JSON.stringify(facetValue)})`, 'background: purple; color: white'); }

    this.research$.pipe(
      take(1),
      map(
        research => research.find(data => data.activeStoreKey === storeKey)
      )
    ).subscribe((activeResearch) => {

      if (activeResearch) {

        switch (facet.type) {
          case 'CheckboxFacet':
            this.azureSearchService.toggle_checkbox_facet(activeResearch.activeStoreKey, facet.key, facetValue.value);
            this.setPage(storeKey, 1);
            this.search(storeKey);
            break;
          case 'RangeFacet':
            if (!facetValue.startDate) {
              if(storeKey === Constants.ChatbotFiltersStores[0]) {
                facetValue.startDate = new Date(Constants.chatbotMinDate);
              } else {
                facetValue.startDate = new Date(Constants.publishedStartDate);
              }
            }
            if (!facetValue.endDate) {
              facetValue.endDate = new Date(new Date().setDate(new Date().getDate() + 1));
            }

            facetValue.startDate.setUTCHours(0);
            facetValue.startDate.setUTCMinutes(0);
            facetValue.startDate.setUTCSeconds(0);
            facetValue.endDate.setUTCHours(23);
            facetValue.endDate.setUTCMinutes(59);
            facetValue.endDate.setUTCSeconds(59);

            this.azureSearchService.set_range_facet_date(activeResearch.activeStoreKey, facet.category,
              facetValue.startDate, facetValue.endDate);

            this.setPage(storeKey, 1);
            this.search(storeKey);
            break;
        }

      }

    });

  }

  /**
   * Perform a suggestions search.
   * @param {string} q - The query string (text from search box).
   */
  public suggest(storeKey: string, q: string): void {
    if (q) {
      this.progressRef = this.ngProgress.ref();
      this.progressRef.start();
      this.azureSearchService.set_input(storeKey, q);
      this.azureSearchService.suggest(storeKey).then(() => {
        this.progressRef.complete();

        if (reportsStores.indexOf(storeKey) >= 0) {

          this.reportsAzssSubject.next(this.azureSearchService.store[storeKey]);  // TODO: this updates suggestions correctly?
        } else {
          if (livefeedStores.indexOf(storeKey) >= 0) {
            this.livefeedAzssSubject.next(this.azureSearchService.store[storeKey]);  // TODO: this updates suggestions correctly?

          }
        }
      });
    }
  }

  /**
   * Switch collections.
   * @param {string} collection - The collection of collectionStoreKey[] to switch to.
   * @param {boolean} loadingFilter - Whether we are loading a saved filter (applies some different search logic).
   * @param {boolean} initOnly - Whether we are just initing a store (applies some different init logic).
   */
  public async ss_set_collection(collection: string, loadingFilter: boolean = false, initOnly: boolean = false, loadUserPrefs: boolean = true): Promise<void> {
    if (this.debug_cfn) { console.log(`%c search.service::ss_set_collection(${collection})`, 'background: purple; color: white'); }

    this.research$.pipe(
      take(1),
      map(
        research => research.find(data => data.activeStoreKey === collection)
      )
    ).subscribe(async (activeResearch) => {

      if (activeResearch) {

        this.azureSearchService.set_global_filter(activeResearch.activeStoreKey, 'vault',
          (this.collectionFilter as any) [activeResearch.activeStoreKey]);
        this.ss_set_subscription(activeResearch.activeStoreKey, '', false);

        // need to update user search preferences - collection (search() reloads user search preferences)
        let searchCollection = {
          collection: collection,
        };
        this.userService.setPreferences({search: searchCollection});

        // important - if query params are coming in on the command line we don't want to clobber them (reset page, etc...)
        if (!initOnly) {
          this.setPage(collection, activeResearch.page);
          this.setQuery(collection, activeResearch.q);
          this.azureSearchService.set_page(activeResearch.activeStoreKey, 1);
        }

        this.azureSearchService.set_input(activeResearch.activeStoreKey, '');

        if (!loadingFilter) {
          await this.search(collection, false, false, loadUserPrefs);
        } else {
          await this.search(collection, false, true);
        }

      }

    });

  }

  /**
   * Sets the 'subscription' globalFilter based on selected 'Intelligence Type' facets and userService.getAcl().
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} subscription - The subscriptionFilter.filters[key == subscription] filter to apply.
   * @param {boolean} toggleFacet - Toggle the Intelligence Type facet (if clicked on in ui), otherwise just update globalFilter.
   */
  public ss_set_subscription(storeKey: string, subscription: string, toggleFacet: boolean = true): void {
    if (this.debug_cfn) { console.log(`%c search.service::ss_set_subscription(storeKey ${storeKey}, subscription ${subscription})`, 'background: purple; color: white'); }

    let filter = '';
    let aclFilter = '';

    // 'my-subscriptions' is a placeholder for user ( this.userService.getAcl() ) Acls
    // populate store[storeKey]subscriptionFilters.filters[key=='my-subscription'] with user Acls
    if (subscription === 'my-subscriptions') {
      let acls = this.userService.getAcl();

      // ensure a consistent order when comparing acl sets
      acls.sort(function(a: string, b: string) {
        return a.localeCompare(b);
      });

      let alcFirst = true;
      for (let acl of acls) {
        if (alcFirst) {
          aclFilter = aclFilter + `acl/any(t: t eq '${acl}')`;
          alcFirst = false
        } else {
          aclFilter = aclFilter + ' or ' + `acl/any(t: t eq '${acl}')`;
        }
      }
      if (aclFilter !== '') {
        aclFilter = "(" + aclFilter + ")";
      }
      this.azureSearchService.set_subscription_filters_filter(storeKey, 'my-subscriptions', aclFilter);
    }

    // only update UI/toggle displayed facet if user clicked on Intelligence Type facet
    if (toggleFacet) {
      this.azureSearchService.toggle_subscription_filters_filter_active(storeKey, subscription);
    }

    // then compile subscription filter from all selected Intelligence Type facets (selected subscriptionFilters.filters)
    let count = 0;
    let subscriptions = this.azureSearchService.get_subscription_filters_filters(storeKey);
    for (let sub of subscriptions) {
      if (sub.active) {
        filter += (count == 0 ? sub.filter : ' or ' + sub.filter);
        count++;
      }
    }
    this.azureSearchService.set_subscription_filters_count(storeKey, count);
    this.azureSearchService.set_global_filter(storeKey, 'subscription', (filter ? '(' + filter + ')' : ''));
  }

  /**
   * Set search orderBy parameter.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} order - The search order parameter.
   */
  public ss_set_order_by(storeKey: string, order: string): void {
    this.azureSearchService.update_search_parameters(storeKey, {orderby: order});
  }

  /**
   * Set search top parameter (page * per page basically).  BUG: don't think this is at all used for anything
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {number} top - The search top parameter.
   */
  public ss_set_top(storeKey: string, top: number): void {
    this.azureSearchService.update_search_parameters(storeKey, {top: top});
  }

  /**
   * Set the search parameters on a store.
   * @param {any} preferences - The preferences object containing parameters to update.
   * @param {boolean} saveUserPrefs - Whether to save the preferences to the user record too.
   */
  public ss_set_search_preferences(storeKey: string, preferences: any, saveUserPrefs = true): void {
    if (this.debug_cfn) { console.log(`%c search.service::set_search_preferences()`, 'background: red; color: white'); }

    this.research$.pipe(
      take(1),
      map(
        research => research.find(data => data.activeStoreKey === storeKey)
      )
    ).subscribe((activeResearch) => {

      if (activeResearch) {

        if (activeResearch.activeStoreKey === 'EMW' ||
          activeResearch.activeStoreKey === 'OperatorProfiles' || activeResearch.activeStoreKey === 'ResearchSpotlight') {
          this.azureSearchService.subscription_filters_clear_all_active(activeResearch.activeStoreKey);
          this.azureSearchService.set_global_filter(activeResearch.activeStoreKey, 'subscription', '');
        }

        if (reportsStores.indexOf(storeKey) >= 0) {
          const order: string = this.sharedService.getCookie(Constants.sortReportPaneCookie) || 'relevance';
          if(order === 'relevance') {
            this.ss_set_order_by(activeResearch.activeStoreKey, this.azureSearchService.get_input(activeResearch.activeStoreKey).length ? '' : 'publishedDate desc');
          } else {
            this.ss_set_order_by(activeResearch.activeStoreKey, order === 'calendar_month' ? 'publishedDate desc' : '');
          }
        } else {
          const order: string = this.sharedService.getCookie(Constants.sortLivefeedPaneCookie) || 'relevance';
          if(order === 'relevance') {
            this.ss_set_order_by(activeResearch.activeStoreKey, this.azureSearchService.get_input(activeResearch.activeStoreKey).length ? '' : 'publishedDate desc');
          } else {
            this.ss_set_order_by(activeResearch.activeStoreKey, order === 'calendar_month' ? 'publishedDate desc' : '');
          }
        }

        this.azureSearchService.update_search_parameters(activeResearch.activeStoreKey, preferences.searchMode);
        this.azureSearchService.update_search_parameters(activeResearch.activeStoreKey, preferences.queryType);

        if ((storeKey !== 'ReportsNew') && (storeKey !== 'LiveFeedNew')) {
          this.ss_set_top(activeResearch.activeStoreKey, parseInt(preferences.top));
        }

        // TEMPORARY: override max results on client models collection to prevent
        //  user requesting too many files to be zipped... will be removed in time
        if (activeResearch.activeStoreKey == 'Downloads') {
          this.ss_set_top(activeResearch.activeStoreKey, 10);
        }

        // BUG: this creates searchFields[] from parsing fields[] which are the user saved 'search within' fields...
        //  however this doesn't work for ClientModels as they use a different result set... James to inquire...

        let fields = null;
        if (activeResearch.activeStoreKey === 'Downloads') {
          fields = ['title', 'fileName', 'parentTitle', 'category'];  // , 'keywords'
        } else {
          fields = ['title', 'descriptionText', 'content', 'companies', 'stockTickers', 'regions', 'basins', 'plays', 'intervals']; // TODO: debug , 'keywords'
        }
        this.azureSearchService.update_search_parameters(activeResearch.activeStoreKey, fields);

        if (saveUserPrefs) {
          this.userService.setPreferences({search: preferences});
        }

      }

    });

  }

  /**
   * Return the active search/suggest query string.
   * @returns {string}
   */
  public getQuery(storeKey: string): string {
    let q: string = '';

    this.research$.pipe(
      take(1),
      map(
        research => research.find(data => data.activeStoreKey === storeKey)
      )
    ).subscribe((activeResearch) => {

      if (activeResearch) {
        q = activeResearch.q;
      }

    });

    return q;
  }

  /**
   * Gets the current page.
   * @returns {number}
   */
  public getPage(storeKey: string): number {
    let page: number = 0;

    this.research$.pipe(
      take(1),
      map(
        research => research.find(data => data.activeStoreKey === storeKey)
      )
    ).subscribe((activeResearch) => {

      if (activeResearch) {
        page = activeResearch.page;
      }

    });

    return page;
  }

  /**
   * Sets the current page.
   * @param {number} page - The page number to set.
   */
  public setPage(storeKey: string, page: number): void {
    this.researchStore.selectResearchByActiveStoreKey(storeKey).pipe(
      take(1)
    ).subscribe(rec => {
      if (rec) {
        if(rec.page !== page) {
          rec.page = page;
          this.researchStore.updateResearchByActiveStoreKey({
            activeStoreKey: storeKey,
            research: rec
          });
        }
        this.azureSearchService.set_page(storeKey, page);
      }
    });
  }

  /**
   * Sets the active search/suggest query string.
   * @param {string} q - The query to set.
   */
  public setQuery(storeKey: string, q: string): void {
    this.researchStore.selectResearchByActiveStoreKey(storeKey).pipe(
      take(1)
    ).subscribe(rec => {
      if (rec) {
        if(rec.q !== q) {
          rec.q = q;
          this.researchStore.updateResearchByActiveStoreKey({
            activeStoreKey: storeKey,
            research: rec
          });
        }
        this.azureSearchService.set_input(storeKey, q);
        if(q === '') {
          this.azureSearchService.clear_suggestions(storeKey);
        }
      }
    });
  }

  /**
   * Sets the active chipContents string.
   * @param {string} q - The query to set.
   */
  public setChipContents(storeKey: string, chipContents: string): void {
    this.researchStore.selectResearchByActiveStoreKey(storeKey).pipe(
      take(1)
    ).subscribe(rec => {
      if (rec) {
        if(rec.chipContents !== chipContents) {
          rec.chipContents = chipContents;
          this.researchStore.updateResearchByActiveStoreKey({
            activeStoreKey: storeKey,
            research: rec
          });
        }
      }
    });
  }

  public filterCount(storeKey: string): number
  {
    let count: number = this.azureSearchService.get_subscription_filters_count(storeKey);
    const store = this.azureSearchService.get_store(storeKey);
    if(store && (store.hasOwnProperty('facetCount')) && (store.facetCount.hasOwnProperty('total'))) {
      count += store.facetCount.total;
    }

    this.research$.pipe(
      take(1),
      map(
        research => research.find(data => data.activeStoreKey === storeKey)
      )
    ).subscribe((activeResearch) => {
      if (activeResearch) {
        if(activeResearch.q.length) {
          count += 1;
        }
      }
    });

    return count;
  }

  // (2 bytes length) + (8 bytes crc64); supports strings up to 65535 bytes
  private generateBinaryBlock(payload: string): Uint8Array {
    const len = payload.length;
    const crcValue = crc64(payload);

    const binaryBlock = new Uint8Array(10);  // 11

    // Write the 'len' value to the first 3 bytes
    binaryBlock[0] = (len >> 8) & 0xFF;  // 1
    binaryBlock[1] = len & 0xFF;  // 2

    // Write the 'crc' value to the next 8 bytes
    for (let i = 0; i < 8; i++) {
      binaryBlock[i + 2] = Number((crcValue >> BigInt(56 - 8 * i)) & BigInt(0xFF));  // i + 3
    }

    return binaryBlock;
  }

  private base64urlEncode(data: Uint8Array): string {
    const binaryString = String.fromCharCode(...data);
    let base64str = btoa(binaryString);
    base64str = base64str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return base64str;
  }

  private base64urlDecode(base64urlstr: string): Uint8Array {
    const base64str = base64urlstr.replace(/-/g, '+').replace(/_/g, '/');
    const binaryString = atob(base64str);
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
    return uint8Array;
  }

  sortObject(obj: any): any {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(item => this.sortObject(item));
    }

    if (typeof obj === 'object') {
      const sortedObj: any = {};
      Object.keys(obj)
        .sort()
        .forEach(key => {
          sortedObj[key] = this.sortObject(obj[key]);
        });
      return sortedObj;
    }

    return obj;
  }

  public facetsDiffUrl(payload: string): string {
    const sortedObject: object = this.sortObject(JSON.parse(payload));
    const sortedJSON: string = JSON.stringify(sortedObject);
    const binaryBlock = this.generateBinaryBlock(sortedJSON);
    const base64urlstr = this.base64urlEncode(binaryBlock);
    return base64urlstr;
  }

  public queryUrl(query: string): string {
    const binaryBlock: Uint8Array = this.generateBinaryBlock(query);
    const base64urlstr: string = this.base64urlEncode(binaryBlock);
    return base64urlstr;
  }

  log(val: any) { console.log(val); }

  clearQueryParams(param: string) {
    let queryParamsSubscription: Subscription = Subscription.EMPTY;
    queryParamsSubscription = this.route.queryParams.subscribe(queryParams => {
      let queryParamsCopy = { ...queryParams };

      // Remove the query parameter
      delete queryParamsCopy[param];

      // Navigate to the same route with updated query parameters
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: queryParamsCopy,
        replaceUrl: true
      });

      queryParamsSubscription.unsubscribe();
    });
  }

  removeQueryParam(param: string) {
    let queryParamsSubscription: Subscription = Subscription.EMPTY;
    queryParamsSubscription = this.route.queryParams.subscribe(queryParams => {
      let queryParamsCopy = { ...queryParams };

      // Remove the query parameter
      delete queryParamsCopy[param];

      // Generate the new URL without the query parameter
      const newUrlWithoutQueryParam = this.router.createUrlTree([], {
        relativeTo: this.route,
        queryParams: queryParamsCopy,
      }).toString();

      // Modify the browser's address bar without triggering a full page reload
      history.replaceState({}, '', newUrlWithoutQueryParam);

      queryParamsSubscription.unsubscribe();
    });
  }

  showQueryParams(): void {
    let queryParams = { ...this.route.snapshot.queryParams };
  }

  public async navigateToPage(storeKey: string, q: string | undefined, page: number): Promise<AzssError | null> {

    return new Promise((resolve, reject) => {

      this.setPage(storeKey, page);

      this.logService.track("search_used", false,{
        vault_collection: storeKey,
        search_properties: q
      });

      this.logService.logPendo('Search Terms', {
        key: storeKey,
        terms: q
      });

      this.search(storeKey).then((error: AzssError | null) => {
        resolve(error);
      }).catch((error: any) => {
        reject(null);
      });

    });

  }

  public checkActiveFilterChanged(storeKey: string, filterName: string): void {
    this.sharedService.updateActiveFilterParameter(storeKey, 'title', filterName).then(() => {
      this.sharedService.testActiveFilterModified(storeKey,
        this.azureSearchService.get_facetsdiff(storeKey, Constants.facetsModifiedIgnoreDates || false)
      );
    });
  }

  getSubscriptionFilterByKey(key: string): string {
    for (const filter of this.subscriptionFilterTemplate.filters) {
      if (filter.key === key) {
        return filter.filter;
      }
    }
    return '';
  }

}

<div class="iv-chatbot">
  <div class="iv-chatbot" [ngClass]="{ 'iv-chatbot__disabled' : !allowedFeature}">
    <div *ngIf="maintenanceMode" class="maintenance">
      <div id="logo">
        <img src="/assets/enverus-logo-black.svg"/>
        <span> &nbsp; | Instant Analyst</span>
      </div>
      <svg viewBox="0 0 80 72" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd"><g transform="translate(-156 -305)" fill="#56BC2F" fill-rule="nonzero"><g transform="translate(156 305)">
          <path d="m16 0c-4.4 0-8 3.6-8 8 0 2.6125 1.3 4.9375 3.25 6.4h-8.05c-1.75 0-3.2 1.45-3.2 3.2v14.4c0 1.75 1.45 3.2 3.2 3.2h3.2v35.2c0 0.87917 0.72083 1.6 1.6 1.6h8c0.87917 0 1.6-0.72083 1.6-1.6v-8h44.8v8c0 0.87917 0.72083 1.6 1.6 1.6h8c0.87917 0 1.6-0.72083 1.6-1.6v-35.2h3.2c1.75 0 3.2-1.45 3.2-3.2v-14.3-0.1c0-1.75-1.45-3.2-3.2-3.2h-8.05c1.95-1.4625 3.25-3.7875 3.25-6.4 0-4.4-3.6-8-8-8s-8 3.6-8 8c0 2.6125 1.3 4.9375 3.25 6.4h-38.5c1.95-1.4625 3.25-3.7875 3.25-6.4 0-4.4-3.6-8-8-8zm0 3.2c2.6708 0 4.8 2.1292 4.8 4.8 0 2.6708-2.1292 4.8-4.8 4.8s-4.8-2.1292-4.8-4.8c0-2.6708 2.1292-4.8 4.8-4.8zm48 0c2.6708 0 4.8 2.1292 4.8 4.8 0 2.6708-2.1292 4.8-4.8 4.8s-4.8-2.1292-4.8-4.8c0-2.6708 2.1292-4.8 4.8-4.8zm-60.8 14.4h6.15l-6.15 10.25v-10.25zm9.85 0h7.5l-8.6 14.4h-7.5l8.6-14.4zm11.2 0h7.5l-8.6 14.4h-7.55l8.65-14.4zm11.2 0h7.5l-8.6 14.4h-7.55l8.65-14.4zm11.2 0h7.5l-8.6 14.4h-7.55l8.65-14.4zm11.2 0h7.5l-8.6 14.4h-7.55l8.65-14.4zm11.2 0h7.5l-8.6 14.4h-7.55l8.65-14.4zm7.75 5.8v8.6h-5.2l5.2-8.6zm-67.2 11.8h2.15c0.54583 0.37083 1.2542 0.37083 1.8 0h0.85v18.9c-0.0125 0.1-0.0125 0.2 0 0.3v6.4 0.15 7.85h-4.8v-33.6zm8 0h5.35c0.54583 0.37083 1.2542 0.37083 1.8 0h9.4c0.54583 0.37083 1.2542 0.37083 1.8 0h9.4c0.54583 0.37083 1.2542 0.37083 1.8 0h9.4c0.54583 0.37083 1.2542 0.37083 1.8 0h4.05v17.6h-44.8v-17.6zm48 0h2.15c0.54583 0.37083 1.2542 0.37083 1.8 0h0.85v33.6h-4.8v-7.75c0.0041664-0.079167 0.0041664-0.17083 0-0.25v-6.4-0.15-19.05zm-48 20.8h44.8v3.2h-44.8v-3.2z"/>
        </g></g></g>
      </svg>
      <div class="maintenance-title"><b>Maintenance in Progress</b></div>
      <div [innerHTML]="maintenanceMode" class="maintenance-message"></div>
      <button matTooltip="Check Maintenance Mode Status" matTooltipPosition="above" (click)="testAndSetMaintenanceMode()">
        Check Status <mat-icon>autorenew</mat-icon>
      </button>
    </div>
    <mat-sidenav-container class="chatbot-content" [ngClass]="{ 'hidden': maintenanceMode }">
        <mat-sidenav #historySidenav mode="side" position="start" class="history-sidenav">
          <iv-chatbot-sidebar></iv-chatbot-sidebar>
        </mat-sidenav>
      <mat-sidenav-content *ngIf="!maintenanceMode">
        <div class="iv-chatbot__header">
          <div class="iv-chatbot__header__row">
            <button *ngIf="!conversationHistoryOpen" matTooltip="Chat History" matTooltipPosition="above" (click)="toggleSidebar()">
              <mat-icon>schedule</mat-icon>
              <div class="chat-history">
                <div>Chat</div>
                <div>History</div>
              </div>
            </button>
            <iv-intelligence-type [store]="store"></iv-intelligence-type>
            <iv-rephrase-question [rephraseQuestion]="rephraseQuestion" [allowedFeature]="allowedFeature" (rephraseQuestionChange)="onRephraseQuestionChange($event)" />
          </div>
          <div class="iv-chatbot__header__row">
            <iv-filters-chip></iv-filters-chip>
          </div>
        </div>
        <div *ngIf="cloudStatus !== 'failed'" id="iv-chatbot-content" class="iv-chatbot-content"
             #chatScroll
             [scrollTop]="chatScroll.scrollHeight">
          <ng-container *ngIf="conversationLoading">
              <iv-inline-loading text="Loading conversation..." [loaderStyle]="{background:'transparent'}" [iconStyle]="{width: '30px', height: '30px'}"></iv-inline-loading>
          </ng-container>
          <ng-container *ngIf="!conversationLoading">
            <ng-container message-clipboard #messageClipboard="d"></ng-container>
            <iv-rating-panel #ratingPanel></iv-rating-panel>
            <ng-container prompt-actions #promptActions="d"></ng-container>
            <ng-container *ngFor="let message of messageList">
              <ng-container *ngIf="message.header?.length">
                <div class="date-header">{{message.header}}</div>
              </ng-container>
              <iv-message [message]="message" [conversationStyle]="conversationStyle">
                <ng-container references *ngIf="(message.quotedReferenceList?.length && message.quotedReferenceList?.length > 0) || (message.noQuotedReferenceList?.length && message.noQuotedReferenceList?.length > 0) || (message.prismReferenceList?.length && message.prismReferenceList?.length > 0)">
                  <iv-references [message]="message"></iv-references>
                </ng-container>
                <ng-container *ngIf="message?.errorStatusCode === 0 || message?.errorStatusCode === undefined || message?.errorStatusCode === null" actions>
                  <mat-divider class="iv-chatbot-divider"/>
                  <div class="iv-chatbot-actions" *ngIf="message.author === AUTHOR.CHATBOT">
                    <div class="iv-chatbot-action-icon" matTooltip="Copy" matTooltipPosition="above">
                      <iv-clipboard-icon (click)="messageClipboard.copyMessage(message)"></iv-clipboard-icon>
                    </div>
                    <div class="iv-chatbot-action-icon" matTooltip="Like" matTooltipPosition="above">
                      <mat-icon (click)="ratingPanel.openPanel(message, uniqueId, true)">thumb_up</mat-icon>
                    </div>
                    <div class="iv-chatbot-action-icon" matTooltip="Dislike" matTooltipPosition="above">
                      <mat-icon (click)="ratingPanel.openPanel(message, uniqueId,false)">thumb_down</mat-icon>
                    </div>
                    <!-- <div class="iv-chatbot-action-icon" matTooltip="Retry" matTooltipPosition="above">
                      <mat-icon (click)="promptActions.retryPrompt(this, message.prompt_id)">refresh</mat-icon>
                    </div> -->
                  </div>
                </ng-container>
              </iv-message>
            </ng-container>
            <ng-container *ngIf="typing">
              <iv-message [message]="{ author: AUTHOR.CHATBOT, user_input: 'Thinking' }">
                <iv-thinking-loader loader/>
              </iv-message>
            </ng-container>
            <ng-container *ngIf="!typing && currentAnswer.response !== ''">
              <iv-message [message]="{ author: AUTHOR.CHATBOT, user_input: currentAnswer.response }">
                <iv-thinking-loader loader/>
              </iv-message>
            </ng-container>
          </ng-container>

        </div>
        <div *ngIf="cloudStatus === 'failed'" class="no-websocket">
          <div>Instant Analyst is down (cannot connect to websocket).  Reach out to support or try again later.</div>
          <div *ngIf="!isProduction">Non-production users must be on the VPN in order to access the Instant Analyst.</div>
        </div>
        <div class="iv-chatbot__prompt-wrapper">
          <button mat-mini-fab color="primary" matTooltip="New Conversation" (click)="restartConversation()">
            <mat-icon>library_add</mat-icon>
          </button>
          <button *ngIf="isDevelopment && (cloudStatus === 'open')" mat-mini-fab color="primary" matTooltip="Connected" class="iv-chatbot__status-button">
            <mat-icon>cloud</mat-icon>
          </button>
          <button *ngIf="isDevelopment && (cloudStatus !== 'open')" mat-mini-fab color="primary" matTooltip="Not Connected" (click)="reconnect()" class="iv-chatbot__status-button">
            <mat-icon>cloud_off</mat-icon>
          </button>
          <iv-prompt-input class="iv-chatbot__prompt" (emitPrompt)="sendUserMessage($event)" [textareaControl]="promptControl" [allowedFeature]="allowedFeature">
            <iv-token-tracker [usedToken]="usedToken"></iv-token-tracker>
          </iv-prompt-input>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <div *ngIf="!allowedFeature && !clickedInterest" class="iv-chatbot__disabled-banner-wrapper">
    <p><strong>Instant Analyst</strong> is an interactive Enverus Intelligence™ AI providing real time insights into our research and publications. Instant Analyst requires an active subscription to our Generative AI offering. Are you interested in this feature?</p>
    <div>
      <button (click)="interested(true)">Yes</button>
      |
      <button (click)="interested(false)">No</button>
    </div>
  </div>
  <div *ngIf="!allowedFeature && clickedInterest" class="iv-chatbot__disabled-banner-wrapper">
    <p>Thank You for your Feedback.</p>
  </div>
</div>

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {NgIf} from '@angular/common';
import {LogService} from '../../services/log/log.service';

@Component({
  standalone: true,
  selector: 'iv-interactive-report',
  templateUrl: './interactive-report.component.html',
  styleUrls: ['./interactive-report.component.scss'],
  imports: [
    NgIf
  ],
  encapsulation: ViewEncapsulation.None
})

export class InteractiveReportComponent implements OnInit{

  metadata: any;
  iframesrc: any;

  constructor(private activatedRoute: ActivatedRoute, protected _sanitizer: DomSanitizer, private logService:LogService) {
  }

  ngOnInit() {
    this.getRouteData();
    this.logService.article = this.metadata.result;
    this.iframeURL();
  }

  private iframeURL() {
    if (this.metadata.url && this.metadata.url.azure_url) {
      console.log(this.metadata.url.azure_url);
      this.iframesrc = this._sanitizer.bypassSecurityTrustResourceUrl(`${this.metadata.url.azure_url}`);

      this.logService.track("interactive_report_viewed", true,{
      });
      // article_filename  <-- MISSING
    }
  }

  private getRouteData() {
    this.activatedRoute.data.subscribe(
      ({metadata}) => {
        this.metadata = metadata;
      });
  }
}
